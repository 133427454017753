const polyfill = () => {
  if (!Object.entries) {
    Object.entries = function(obj: any) {
      let ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    };
  }
};

export default polyfill();
